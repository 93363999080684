import { lazy } from "react";
import MainLayout from "../../../layout/main/MainLayout";

const homeRoutes = [
  {
    path: "/",
    element: lazy(() => import("./Home")),
    layout: MainLayout
  },{
    path: "/bluecollar", // For localhost:3000
    element: lazy(() => import("./Home")),
    layout: MainLayout,
  }
];

export default homeRoutes;
