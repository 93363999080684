import { createSlice } from "@reduxjs/toolkit";

// Initial state
const initialState = {
  domainName: "",
};

const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    // Action to set the domain name
    setDomainName: (state, action) => {
      state.domainName = action.payload;
    },
  },
});

// Export actions
export const { setDomainName } = domainSlice.actions;

// Export reducer
export default domainSlice.reducer;
