import React from "react";
import { Link } from "react-router-dom";

const MobileHeader = ({ isOpen, handleShowLogin, handleMobileMenuOpen }) => {
  return (
    <div className="mobile-header">
      <div className="logo">
        <Link to="/">
          <img src="images/SBJFullLogo.png" alt="" title="" />
        </Link>
      </div>

      <div className="nav-outer clearfix">
        <div className="outer-box">
          <div className="login-box" onClick={handleShowLogin}>
            <p className="call-modal">
              <span className="icon-user"></span>
            </p>
          </div>

          <p
            onClick={handleMobileMenuOpen}
            className="mobile-nav-toggler navbar-trigger"
          >
            <span
              className={`${
                isOpen ? "flaticon-close-1 fs-5" : "flaticon-menu-1"
              }`}
            ></span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
