import { lazy } from "react";
import MainLayout from "../../../layout/main/MainLayout";

const jobDataRooutes = [
  {
    path: "/jd/:id",
    element: lazy(() => import("./JobData")),
    layout: MainLayout,
  },
];

export default jobDataRooutes;
