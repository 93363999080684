import aboutRoutes from "../components/pages/bluecollar/about/aboutRoutes";
import blogRoutes from "../components/pages/bluecollar/blog/blogRoutes";
import contactRoutes from "../components/pages/bluecollar/contact/contactRoutes";
import homeRoutes from "../components/pages/bluecollar/home/homeRoutes";
import jobDataRooutes from "../components/pages/bluecollar/job data/jobDataRoutes";
import jobListRoutes from "../components/pages/bluecollar/job list/jobListRoutes";

const blueCollarRoutes = [
  ...homeRoutes,
  ...jobListRoutes,
  ...jobDataRooutes,
  ...aboutRoutes,
  ...blogRoutes,
  ...contactRoutes
];

export default blueCollarRoutes;
